<template>
    <div class="center" style="padding-top: 45px;">
        <div style="height: 80px;text-align: center">

            <span><span
                    style="height: 20px;background-color: #fc7c40;color: white;padding: 5px;
                    line-height: 20px;margin-right: 5px;vertical-align: text-bottom">{{ detaiItem.lsn1 }}</span>
                <span style="font-size: 24px;font-weight: bold;color: black">{{detaiItem.ti}}</span></span>
        </div>
        <div style="margin-bottom: 20px;height: 230px;border: 1px solid rgba(220, 220, 220, .5);padding: 30px 40px">
            <Row>
                <Col span="8" style="font-size: 14px">
                    <div style="margin-bottom: 10px;width: 240px"><span style="line-height: 25px">专利类型：
            {{detaiItem.cls == 'fmzl' ? '发明专利':''}}
            {{detaiItem.cls == 'syxx' ? '实用新型':''}}
            {{detaiItem.cls == 'wgzl' ? '外观类型':''}}
          </span></div>
                    <div style="margin-bottom: 10px;width: 240px"><span style="line-height: 25px">专利保护年限：20年</span>
                    </div>
                    <div style="margin-bottom: 10px;width: 240px;    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;"><span style="line-height: 25px"
                                         :title="detaiItem.pin">发明设计人：{{ detaiItem.in }}</span></div>
                    <div style="margin-bottom: 10px;width: 240px"><span
                            style="line-height: 25px">主分类号：{{detaiItem.cc1}}</span></div>
                </Col>
                <Col span="8">
                    <div style="margin-bottom: 10px;width: 240px"><span
                            style="line-height: 25px">申请号：{{detaiItem.an}}</span></div>
                    <div style="margin-bottom: 10px;width: 240px"><span
                            style="line-height: 25px">申请日期：{{detaiItem.ad}}</span></div>
                    <div style="margin-bottom: 10px;width: 240px"><span
                            style="line-height: 25px">申请人：{{detaiItem.pa}}</span></div>
                    <div style="margin-bottom: 10px;width: 240px"><span style="line-height: 25px;    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;" :title="detaiItem.aa">申请人地址：{{detaiItem.aa}}</span></div>
                </Col>
                <Col span="8">
                    <div style="margin-bottom: 10px;width: 240px"><span
                            style="line-height: 25px">授权公众号：{{detaiItem.pn}}</span></div>
                    <div style="margin-bottom: 10px;width: 240px"><span
                            style="line-height: 25px">公告日：{{detaiItem.pd}}</span></div>
                    <div style="margin-bottom: 10px;width: 240px"><span
                            style="line-height: 25px">代理人：{{detaiItem.agt}}</span></div>
                    <div style="margin-bottom: 10px;width: 240px"><span
                            style="line-height: 25px">代理机构：{{detaiItem.agc}}</span></div>
                </Col>

            </Row>
        </div>
        <div style="padding-top: 30px;padding-bottom: 40px;border:1px solid rgba(220, 220, 220, .5);padding-left: 40px;padding-right: 80px;height: auto">
            <div style="font-size: 16px;margin-bottom: 45px"><span>摘要</span></div>
            <div style="width: 1080px">
                <p style="line-height: 25px">
                    {{detaiItem.ab}}
                </p>
            </div>
        </div>

        <div style="margin-top:20px;border: 1px solid rgba(220, 220, 220, .5);padding-top: 30px;padding-bottom: 40px;padding-left: 40px;">
            <div style="font-size: 16px;margin-bottom:30px"><span>权利要求</span></div>
            <div style="width: 1080px">
                <div style="line-height: 25px" v-html="detaiItem.clm">
                </div>
            </div>
        </div>


        <div v-if="detaiItem.imagePath"
             style="margin-top:20px;height: 350px;border: 1px solid rgba(220, 220, 220, .5);padding-top: 30px;padding-bottom: 40px;padding-left: 40px;">
            <div style="font-size: 16px;margin-bottom:30px"><span>专利图片</span></div>
            <div style="width: 1080px;line-height: 65px;text-align: center">
                <img :src="detaiItem.imagePath" alt="" style="max-width: 100%;height:250px">
            </div>
        </div>

        <div style="height: 150px;display: flex;align-items: center;justify-content: center">
            <div style="margin-right:20px;display:none;height: 35px;width: 120px;line-height: 35px;text-align: center;background-color: #fc7c40;color: white">
                下载专利pdf
            </div>
            <div style="cursor:pointer;height: 35px;width: 120px;line-height: 35px;text-align: center;color: #fc7c40;border: 1px solid #fc7c40"
                 @click="openZoosUrl">
                联系专利
            </div>
        </div>
    </div>
</template>

<script>
    import {getPatentDetailByzlh} from "../../plugins/api/Shop";

    export default {
        name: "ResourceDetail",
        data() {
            return {
                detaiItem: {
                    ab: '摘要',
                    abPicPath: '专利图片',
                    ad: '申请日',
                    agc: '代理机构',
                    agt: '代理人',
                    an: '申请号',
                    aa: '申请人地址',
                    clm: '权利要求',
                    lsn1: '法律状态',
                    pa: '申请人',
                    pd: '公告日',
                    cc1: '主分类号',
                    in: '发明设计人',
                    pn: '授权公告号',
                    cls: '专利类型',
                    ti: '专利名称'
                },
            }
        },
        mounted() {
            let an = this.$route.query.an
            let forms = new FormData()
            forms.append('patentNo', an)
            getPatentDetailByzlh(forms).then((res) => {
                if (res['@success']) {
                    this.detaiItem = res['@success']
                    this.detaiItem.imagePath = this.detaiItem.abdraw ? '/byzlhImg/patent_img' + this.detaiItem.imagePath + this.detaiItem.abdraw : ''
                }
            })

        },
        methods: {
            openZoosUrl() {
                openZoosUrl('chatwin');
            }
        }
    }
</script>

<style scoped>
    .center {
        width: 1200px;
        margin: 0 auto;
    }
</style>
