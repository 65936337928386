import {get,post,del,put} from '../http/http'
import {storebaseUrl,zlhUrl} from "../http/baseUrl";
import baseUrl from "../http/baseUrl";

//专家专利列表
export const getpatentbyexpertList=(params)=> {
  return get(`${storebaseUrl}/v2/professorPatent/list`,params, {hideLoading: true});
}

//获取专家和高校的店铺详情
export const getshopDetail=(params)=> {
  return get(`${storebaseUrl}/v2/shop/detail`,params, {hideLoading: true});
}

//高校专利列表
export const getshopbycollegeList=(params)=> {
  return get(`${storebaseUrl}/v2/collegePatent/list`,params, {hideLoading: true});
}

//店铺咨询列表
export const shopCommentList=(data)=> {
  return get(`${baseUrl}/v1/shop/comment/list`,data, {hideLoading: true});
}

//发送咨询
export const sendComment=(data)=> {
  return post(`${baseUrl}/v1/shop/comment/save`,data, {hideLoading: true});
}

//通过高校名称获取专家列表
export const getprofessorbycollegeList=(params)=> {
  return get(`${storebaseUrl}/v1/professor/list`,params, {hideLoading: true});
}

//通过高校名称获得专利成果列表
export const getresultsbycollege=(params)=>{
  return get(`${storebaseUrl}/v1/patent/achievement/search`,params, {hideLoading: true});
}

//第三方店铺详情
export const getOutsideStoreDetail=(params)=>{
  return get(`${baseUrl}/v1/shop/detail`,params, {hideLoading: true});
}

//专利详情by专利汇
export const getPatentDetailByzlh = (params) => {
  return post(`${zlhUrl}/patent/searchPatentDetail`,params,{hideLoading: false,ContentType:'application/x-www-form-urlencoded'})
};
